// Generouted, changes to this file will be overriden
/* eslint-disable */

import { hooks, utils } from "@generouted/react-router/client";
import { components } from "@web-monorepo/routing";

export type Path =
  | `/`
  | `/SignupCoordinatorContainer/login/:provider`
  | `/SignupCoordinatorContainer/oidc-signup`
  | `/SignupCoordinatorContainer/signup`
  | `/SignupCoordinatorContainer/signup/:code`
  | `/classdojoSchoolSignup`
  | `/classes/:classroomId`
  | `/classes/:classroomId/dashboard/attendance`
  | `/classes/:classroomId/dashboard/parentAccounts`
  | `/classes/:classroomId/dashboard/reports`
  | `/classes/:classroomId/dashboard/reports/:studentId`
  | `/classes/:classroomId/dashboard/studentAccounts`
  | `/classes/:classroomId/dojoIslands`
  | `/classes/:classroomId/dojoIslands/intro`
  | `/classes/:classroomId/events`
  | `/classes/:classroomId/events/:eventId`
  | `/classes/:classroomId/events/:eventId/edit`
  | `/classes/:classroomId/events/create`
  | `/classes/:classroomId/messaging`
  | `/classes/:classroomId/messaging/:channelId`
  | `/classes/:classroomId/messaging/classroomBroadcast`
  | `/classes/:classroomId/messaging/group-chat-launch`
  | `/classes/:classroomId/pd/finish`
  | `/classes/:classroomId/pd/qa`
  | `/classes/:classroomId/pd/setup`
  | `/classes/:classroomId/pd/slides`
  | `/classes/:classroomId/pd/start`
  | `/classes/:classroomId/points`
  | `/classes/:classroomId/portfolio`
  | `/classes/:classroomId/portfolio-v2/*`
  | `/classes/:classroomId/portfolio/activity/:assignmentId`
  | `/classes/:classroomId/portfolio/pending`
  | `/classes/:classroomId/portfolio/student/:studentId`
  | `/classes/:classroomId/reports/:date/open`
  | `/classes/:classroomId/reports/open`
  | `/classes/:classroomId/story`
  | `/classes/:classroomId/story/:postId`
  | `/classes/:classroomId/studentStory/:studentId`
  | `/classes/createOnSignup`
  | `/classroomPreview/:classroomId`
  | `/codeLogin`
  | `/data-export`
  | `/forbidden`
  | `/forceReset`
  | `/forgot`
  | `/forgot/:passwordResetId`
  | `/launchpad`
  | `/launchpad-v2`
  | `/login`
  | `/logout`
  | `/messaging/group-chat-launch`
  | `/not-verified-class/:classroomId`
  | `/notFound`
  | `/oidc/callback`
  | `/oidc/error/:error`
  | `/oidc/link`
  | `/oidc/login-to-link/:provider`
  | `/oidc/login/:provider`
  | `/oidc/signup`
  | `/oidc/success`
  | `/onboarding-v2/:classroomId/info`
  | `/onboarding-v2/:classroomId/invites`
  | `/onboarding-v2/:classroomId/parents`
  | `/onboarding-v2/:classroomId/school`
  | `/onboarding-v2/:classroomId/skills`
  | `/onboarding-v2/:classroomId/students`
  | `/onboarding-v2/create`
  | `/onboarding-v2/emailVerified`
  | `/onboarding-v2/joinSchool`
  | `/onboarding-v2/signup`
  | `/onboarding-v2/signup/:schoolId`
  | `/onboarding-v2/verifyEmail/:schoolId`
  | `/onboarding-v3/login/:provider`
  | `/onboarding-v3/oidc-signup`
  | `/onboarding-v3/signup`
  | `/onboarding-v3/signup/:code`
  | `/onboarding/schools/:schoolId/directory/teachers`
  | `/pd`
  | `/photobook/:classId`
  | `/photobook/:classId/featured`
  | `/photobook/:classId/finish/:photobookId`
  | `/photobook/:classId/photos`
  | `/photobook/:classId/start`
  | `/photobook/:classId/title`
  | `/redeem/:code`
  | `/register`
  | `/schools/:joiningSchoolId/join`
  | `/schools/:joiningSchoolId/transfer_personal_classes`
  | `/schools/:schoolId`
  | `/schools/:schoolId/directory`
  | `/schools/:schoolId/directory/*`
  | `/schools/:schoolId/events`
  | `/schools/:schoolId/events/:eventId`
  | `/schools/:schoolId/events/:eventId/edit`
  | `/schools/:schoolId/events/create`
  | `/schools/:schoolId/highlights`
  | `/schools/:schoolId/info`
  | `/schools/:schoolId/insights`
  | `/schools/:schoolId/messages`
  | `/schools/:schoolId/messages/:messageThreadId`
  | `/schools/:schoolId/messages/:messageThreadId/details`
  | `/schools/:schoolId/messaging`
  | `/schools/:schoolId/messaging/:messageThreadId`
  | `/schools/:schoolId/points`
  | `/schools/:schoolId/points-landing`
  | `/schools/:schoolId/points/allSkills`
  | `/schools/:schoolId/points/allStudents`
  | `/schools/:schoolId/points/classes`
  | `/schools/:schoolId/points/create`
  | `/schools/:schoolId/points/skills`
  | `/schools/:schoolId/schoolDirectory`
  | `/schools/:schoolId/select`
  | `/schools/:schoolId/stats`
  | `/schools/:schoolId/story`
  | `/schools/:schoolId/story/:postId`
  | `/schools/:schoolId/studentDirectory`
  | `/schools/create`
  | `/schools/directory`
  | `/schools/directory/story`
  | `/schools/events`
  | `/schools/events/create`
  | `/schools/highlights`
  | `/schools/join/search`
  | `/schools/messages`
  | `/schools/messaging`
  | `/schools/messaging/:messageThreadId`
  | `/schools/pick`
  | `/schools/points`
  | `/schools/points/classes`
  | `/schools/points/skills`
  | `/schools/story`
  | `/schools/story/:storyId`
  | `/schools/studentDirectory`
  | `/sidekick`
  | `/sidekick/conversation`
  | `/sidekick/conversation/:conversationId`
  | `/sidekick/create`
  | `/sidekick/create/:promptName`
  | `/signup`
  | `/signup/:code`
  | `/singleLinkSignup/:code`
  | `/triggerErrorOnClick`
  | `/triggerErrorOnRender`
  | `/verify`
  | `/visitIsland`;

export type Params = {
  "/SignupCoordinatorContainer/login/:provider": { provider: string };
  "/SignupCoordinatorContainer/signup/:code": { code: string };
  "/classes/:classroomId": { classroomId: string };
  "/classes/:classroomId/dashboard/attendance": { classroomId: string };
  "/classes/:classroomId/dashboard/parentAccounts": { classroomId: string };
  "/classes/:classroomId/dashboard/reports": { classroomId: string };
  "/classes/:classroomId/dashboard/reports/:studentId": { classroomId: string; studentId: string };
  "/classes/:classroomId/dashboard/studentAccounts": { classroomId: string };
  "/classes/:classroomId/dojoIslands": { classroomId: string };
  "/classes/:classroomId/dojoIslands/intro": { classroomId: string };
  "/classes/:classroomId/events": { classroomId: string };
  "/classes/:classroomId/events/:eventId": { classroomId: string; eventId: string };
  "/classes/:classroomId/events/:eventId/edit": { classroomId: string; eventId: string };
  "/classes/:classroomId/events/create": { classroomId: string };
  "/classes/:classroomId/messaging": { classroomId: string };
  "/classes/:classroomId/messaging/:channelId": { classroomId: string; channelId: string };
  "/classes/:classroomId/messaging/classroomBroadcast": { classroomId: string };
  "/classes/:classroomId/messaging/group-chat-launch": { classroomId: string };
  "/classes/:classroomId/pd/finish": { classroomId: string };
  "/classes/:classroomId/pd/qa": { classroomId: string };
  "/classes/:classroomId/pd/setup": { classroomId: string };
  "/classes/:classroomId/pd/slides": { classroomId: string };
  "/classes/:classroomId/pd/start": { classroomId: string };
  "/classes/:classroomId/points": { classroomId: string };
  "/classes/:classroomId/portfolio": { classroomId: string };
  "/classes/:classroomId/portfolio-v2/*": { classroomId: string; "*": string };
  "/classes/:classroomId/portfolio/activity/:assignmentId": { classroomId: string; assignmentId: string };
  "/classes/:classroomId/portfolio/pending": { classroomId: string };
  "/classes/:classroomId/portfolio/student/:studentId": { classroomId: string; studentId: string };
  "/classes/:classroomId/reports/:date/open": { classroomId: string; date: string };
  "/classes/:classroomId/reports/open": { classroomId: string };
  "/classes/:classroomId/story": { classroomId: string };
  "/classes/:classroomId/story/:postId": { classroomId: string; postId: string };
  "/classes/:classroomId/studentStory/:studentId": { classroomId: string; studentId: string };
  "/classroomPreview/:classroomId": { classroomId: string };
  "/forgot/:passwordResetId": { passwordResetId: string };
  "/not-verified-class/:classroomId": { classroomId: string };
  "/oidc/error/:error": { error: string };
  "/oidc/login-to-link/:provider": { provider: string };
  "/oidc/login/:provider": { provider: string };
  "/onboarding-v2/:classroomId/info": { classroomId: string };
  "/onboarding-v2/:classroomId/invites": { classroomId: string };
  "/onboarding-v2/:classroomId/parents": { classroomId: string };
  "/onboarding-v2/:classroomId/school": { classroomId: string };
  "/onboarding-v2/:classroomId/skills": { classroomId: string };
  "/onboarding-v2/:classroomId/students": { classroomId: string };
  "/onboarding-v2/signup/:schoolId": { schoolId: string };
  "/onboarding-v2/verifyEmail/:schoolId": { schoolId: string };
  "/onboarding-v3/login/:provider": { provider: string };
  "/onboarding-v3/signup/:code": { code: string };
  "/onboarding/schools/:schoolId/directory/teachers": { schoolId: string };
  "/photobook/:classId": { classId: string };
  "/photobook/:classId/featured": { classId: string };
  "/photobook/:classId/finish/:photobookId": { classId: string; photobookId: string };
  "/photobook/:classId/photos": { classId: string };
  "/photobook/:classId/start": { classId: string };
  "/photobook/:classId/title": { classId: string };
  "/redeem/:code": { code: string };
  "/schools/:joiningSchoolId/join": { joiningSchoolId: string };
  "/schools/:joiningSchoolId/transfer_personal_classes": { joiningSchoolId: string };
  "/schools/:schoolId": { schoolId: string };
  "/schools/:schoolId/directory": { schoolId: string };
  "/schools/:schoolId/directory/*": { schoolId: string; "*": string };
  "/schools/:schoolId/events": { schoolId: string };
  "/schools/:schoolId/events/:eventId": { schoolId: string; eventId: string };
  "/schools/:schoolId/events/:eventId/edit": { schoolId: string; eventId: string };
  "/schools/:schoolId/events/create": { schoolId: string };
  "/schools/:schoolId/highlights": { schoolId: string };
  "/schools/:schoolId/info": { schoolId: string };
  "/schools/:schoolId/insights": { schoolId: string };
  "/schools/:schoolId/messages": { schoolId: string };
  "/schools/:schoolId/messages/:messageThreadId": { schoolId: string; messageThreadId: string };
  "/schools/:schoolId/messages/:messageThreadId/details": { schoolId: string; messageThreadId: string };
  "/schools/:schoolId/messaging": { schoolId: string };
  "/schools/:schoolId/messaging/:messageThreadId": { schoolId: string; messageThreadId: string };
  "/schools/:schoolId/points": { schoolId: string };
  "/schools/:schoolId/points-landing": { schoolId: string };
  "/schools/:schoolId/points/allSkills": { schoolId: string };
  "/schools/:schoolId/points/allStudents": { schoolId: string };
  "/schools/:schoolId/points/classes": { schoolId: string };
  "/schools/:schoolId/points/create": { schoolId: string };
  "/schools/:schoolId/points/skills": { schoolId: string };
  "/schools/:schoolId/schoolDirectory": { schoolId: string };
  "/schools/:schoolId/select": { schoolId: string };
  "/schools/:schoolId/stats": { schoolId: string };
  "/schools/:schoolId/story": { schoolId: string };
  "/schools/:schoolId/story/:postId": { schoolId: string; postId: string };
  "/schools/:schoolId/studentDirectory": { schoolId: string };
  "/schools/messaging/:messageThreadId": { messageThreadId: string };
  "/schools/story/:storyId": { storyId: string };
  "/sidekick/conversation/:conversationId": { conversationId: string };
  "/sidekick/create/:promptName": { promptName: string };
  "/signup/:code": { code: string };
  "/singleLinkSignup/:code": { code: string };
};

export type ModalPath = never;

export const { Link, Navigate } = components<Path, Params>();
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>();
export const { redirect } = utils<Path, Params>();
